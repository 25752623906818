@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
body {
  font-family: "DM Sans", sans-serif;
}

option {
  color: black;
}

.chakra-tabs__tab:focus {
  box-shadow: none !important;
}

.privacy-page {
  width: 90%;
  margin: auto;
  max-width: 1024px;
}

.privacy-page .header {
  padding: 16px 0;
  border-bottom: 2px solid white;
}

.privacy-page .header .title {
  font-size: 40px;
  line-height: 60px;
  font-weight: 700;
  margin: 0;
}

.privacy-page .translations-content-container {
  padding: 16px 0px;
}

.privacy-page h2 {
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
  margin-bottom: 16px;
}

.privacy-page h1 {
  font-size: 40px;
  line-height: 60px;
  font-weight: 700;
  margin-bottom: 16px;
}

.privacy-page p {
  margin-bottom: 16px;
}

.privacy-page ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.privacy-page li {
  margin-bottom: 16px;
}

.privacy-page .footer {
  border-top: 2px solid white;
  padding: 16px 0px 0px 0px;
}